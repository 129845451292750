<template>
  <v-row justify="space-around">
    <v-dialog
      v-model="showDialog"
      transition="dialog-bottom-transition"
      max-width="80vw"
    >
      <template #activator="{ on, attrs }">
        <v-icon
          class="fullscreen-icon"
          color="primary"
          v-bind="attrs"
          v-on="on"
        >
          fullscreen
        </v-icon>
      </template>
      <template #default="dialog">
        <v-card>
          <v-toolbar
            class="mb-6"
            color="primary"
            dark
          >
            {{ $t(`organization.statistics.${category}`) }}
          </v-toolbar>
          <div class="px-6">
            <bar-chart
              v-if="showChart && (category === 'projectsWithMostUsers' || category === 'usersByAge')"
              :chartdata="data[category].data"
              :labels="data[category].labels"
            />
            <line-chart
              v-if="showChart && category === 'enrollmentsTimeline'"
              :chartdata="data[category].data"
              :labels="data[category].labels"
            />
            <vert-bar-chart
              v-if="showChart && category === 'usersByCountry'"
              :chartdata="data[category].data"
              :labels="data[category].labels"
            />
            <pie-chart
              v-if="showChart && category === 'timePerProject'"
              :chartdata="data[category].data"
              :labels="data[category].labels"
            />
          </div>
          <v-card-actions class="justify-end pa-4">
            <v-btn
              text
              @click="dialog.value = false"
            >
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
  </v-row>
</template>

<script>
import i18nCountries from '@/utils/mixins/i18nCountries'

export default {
  name: 'Modal',
  components: {
    BarChart: () => import('@/utils/charts/horizBars'),
    LineChart: () => import('@/utils/charts/vertLine'),
    VertBarChart: () => import('@/utils/charts/vertBars'),
    PieChart: () => import('@/utils/charts/pie'),
  },
  mixins: [i18nCountries],
  props: {
    category: {
      type: String,
      default: null,
    },
    data: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      showDialog: false,
      showChart: false,
    }
  },
  watch: {
    showDialog(val) {
      if (val) this.$nextTick(() => { this.showChart = val })
      else setTimeout(() => { this.showChart = val }, 500)
    },
  },
}
</script>

<style lang="scss" scoped>
.fullscreen-icon {
  width: 12px;
  font-size: 20px;
}
</style>
